import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAnnouncementsAsync } from "../slices/webChatSlice";
import moment from "moment";

const Announcements = () => {
  const { announcements } = useSelector((state) => state.webChat);
  const dispatch = useDispatch();
  useEffect(() => {
    getAnnouncementsAsync(dispatch);
  }, [dispatch]);

  return (
    <>
      <b className="mb-3 d-block">📌Smart Notice Board</b>
      {announcements && announcements.length ? (
        announcements.map((announcement, index) => {
          return (
            <div key={index}>
              <b className="mb-3 d-block">Notice {index + 1}</b>
              <b>🔹Heading: {announcement.subject}</b>
              <p>
                🔹Context: {announcement.body}
                <br></br>
                <small>
                  <u>
                    Date:
                    {moment(announcement.dateCreated)
                      .add(2, "hours")
                      .format("YYYY-MM-DD HH:mm")}
                  </u>
                </small>
              </p>
              <hr></hr>
            </div>
          );
        })
      ) : (
        <>There is no Notice for past 30 days.</>
      )}
      <p>
        Thank you for checking out our announcements! 📚 If you need further
        assistance, feel free to reach out. 🎓
      </p>
    </>
  );
};

export default Announcements;
