import { useState } from "react";
import {
  setAppendedComps,
  setOpenChatData,
  setOpenChatId,
  setShowMainOptions,
} from "../slices/webChatSlice";
import { useDispatch, useSelector } from "react-redux";
import SelfHelpService from "../services/SelfHelpService";
import PendingIcon from "@mui/icons-material/Pending";
var saIdParser = require("south-african-id-parser");

const selfHelpOptions = [
  {
    id: "1",
    title: "Helpful Links",
    emoji: "🔗",
    description: "Quick access to essential resources.",
  },
  {
    id: "2",
    title: "Admission Status",
    emoji: "📋",
    description: "Check your application status effortlessly.",
  },
  {
    id: "3",
    title: "Online Application",
    emoji: "📝",
    description: "Apply online with ease.",
  },
  {
    id: "4",
    title: "Financial Support",
    emoji: "💸",
    description: "Explore funding options and bursaries.",
  },
  {
    id: "5",
    title: "How-to and FAQs",
    emoji: "❓",
    description: "Find answers to your questions easily.",
  },
  // {
  //   id: "7",
  //   title: "Reset Password/Username",
  //   emoji: "🔐",
  //   description: "Manage your account credentials securely.",
  // },
  {
    id: "8",
    title: "eVoting (coming soon)",
    emoji: "📊",
    description: "Join online elections and surveys.",
  },
];

const convertToHTML = (responseText) => {
  const lines = responseText.split("\n").map((line) => line.trim());
  let htmlString = '<div class="courses-applied-for">';
  lines.forEach((line) => {
    if (line.startsWith("Courses Applied For")) htmlString += `<b>${line}</b>`;
    else if (line.startsWith("Choice")) htmlString += `<div>${line}`;
    else if (
      line.startsWith("Qualification") ||
      line.startsWith("Status") ||
      line.startsWith("Offering Type")
    )
      htmlString += `<p>${line}</p></div><br/>`;
  });
  htmlString += "</div>";
  return htmlString;
};

const isValidYear = (year) => {
  if (isNaN(year) || !Number.isInteger(Number(year))) {
    return false;
  }

  const yearNum = Number(year);
  const currentYear = new Date().getFullYear();
  if (yearNum < 0 || yearNum > currentYear + 2) {
    return false;
  }

  return true;
};

const PromptStudentNumber = () => {
  const dispatch = useDispatch();
  const { appendedComps } = useSelector((state) => state.webChat);
  const [idNumber, setIdNumber] = useState("");
  const [year, setYear] = useState("2025");
  const [warning, setWarning] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const triggerReset = () => {
    dispatch(setOpenChatId(null));
    dispatch(setOpenChatData(null));
    dispatch(setAppendedComps([]));
    dispatch(setShowMainOptions(true));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { isValid } = saIdParser.parse(idNumber);

    if (!isValid) {
      setWarning("Not a valid South African number.");
      return;
    }

    if (!isValidYear(year)) {
      setWarning("Not a valid academic year.");
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await new SelfHelpService().checkAdmissionStatus(
        idNumber,
        year
      );
      const newMessage = (
        <div className="chat-message-container other-category">
          <div className="chat-message">
            <p>{response.heading}</p>
            <div
              className="response-container"
              dangerouslySetInnerHTML={{
                __html: convertToHTML(response.courseDetails),
              }}
            />
            <br></br>
            <button type="button" onClick={triggerReset} className="chat-btn">
              Main Menu
            </button>
          </div>
        </div>
      );
      dispatch(setAppendedComps([...appendedComps.slice(0, -1), newMessage]));
    } catch (e) {
      alert("An error occurred while trying to process your request.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div className="chat-message-container message">
        <div className="chat-message">
          To check your <b>admission status</b>, please enter your ID number and
          admission year in the fields below.
        </div>
      </div>
      <div className="chat-message-container other-category">
        <div className="chat-message">
          <form onSubmit={handleSubmit}>
            <input
              type="number"
              className="id-number"
              pattern="\d{13}"
              maxLength="13"
              required
              placeholder="Enter ID Number"
              value={idNumber}
              onChange={(e) => {
                setIdNumber(e.target.value);
                setWarning();
              }}
              style={{ width: "100%" }}
            />
            <input
              type="number"
              className="year"
              minLength="4"
              maxLength="4"
              required
              placeholder="Year"
              value={year}
              onChange={(e) => {
                setYear(e.target.value);
                setWarning();
              }}
              style={{ width: "100%" }}
            />
            {isSubmitting ? (
              <button type="submit" className="main-btn d-block" disabled>
                <PendingIcon />
              </button>
            ) : (
              <button type="submit" className="main-btn d-block">
                Send
              </button>
            )}
          </form>
          <br></br>
          {warning && (
            <p>
              <b>Warning:</b> {warning}
            </p>
          )}
        </div>
      </div>
    </>
  );
};

const SelfHelp = () => {
  const dispatch = useDispatch();
  const { appendedComps } = useSelector((state) => state.webChat);

  const handleSelfHelpOptionClick = (event, option) => {
    event.stopPropagation();
    let newMessage;
    if (option.id === "1") {
      newMessage = (
        <div className="chat-message-container">
          <div className="chat-message">
            <h6>List of Helpful Links</h6>
            <ul>
              <li>
                <a
                  href="https://ie.wsu.ac.za/pls/prodi41/w99pkg.mi_login"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ITS link
                </a>
              </li>
              <li>
                <a
                  href="https://www.wsu.ac.za/index.php/en/study-with-us/fees-financial-support"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Financial Support
                </a>
              </li>
              <li>
                <a
                  href="https://outlook.office.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Student/Staff Email
                </a>
              </li>
              <li>
                <a
                  href="https://wiseup.wsu.ac.za/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Moodle
                </a>
              </li>
              <li>
                <a
                  href="http://students.wsu.ac.za/mobileverify/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Mobile Verify
                </a>
              </li>
              <li>
                <a
                  href="https://wsulib.summon.serialssolutions.com/#!/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  OPAC
                </a>
              </li>
              <li>
                <a
                  href="https://helpdesk.wsu.ac.za/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Service desk
                </a>
              </li>
            </ul>
            <p>
              If you'd like to return to the main menu, click '<b>home</b>'
              on top.
            </p>
          </div>
        </div>
      );
    } else if (option.id === "2") {
      newMessage = <PromptStudentNumber />;
    } else if (option.id === "3") {
      newMessage = (
        <div className="chat-message-container">
          <div className="chat-message">
            <p>
              Online Applications are done via{" "}
              <a
                href="https://applications.wsu.ac.za/"
                target="_blank"
                rel="noopener noreferrer"
              >
                ITS
              </a>
            </p>
            <p>
              If you'd like to return to the main menu, click '<b>home</b>'
              on top.
            </p>
          </div>
        </div>
      );
    } else if (option.id === "4") {
      newMessage = (
        <div className="chat-message-container">
          <div className="chat-message">
            <p>
              Please visit Financial Aid Offices(FAB):
              <a
                href="https://www.wsu.ac.za/index.php/en/study-with-us/fees-financial-support"
                target="_blank"
                rel="noopener noreferrer"
              >
                Fees & Financial Support
              </a>
            </p>
            <p>
              If you'd like to return to the main menu, click '<b>home</b>'
              on top.
            </p>
          </div>
        </div>
      );
    } else if (option.id === "5") {
      newMessage = (
        <div className="chat-message-container">
          <div className="chat-message">
            <p>
              We will redirect you to our FAQ page. Please hold tight we're
              about to go. 🧠📚
            </p>
            <p>
              If you'd like to return to the main menu, click '<b>home</b>'
              on top.
            </p>
          </div>
        </div>
      );
    } else if (option.id === "7") {
      newMessage = (
        <div className="chat-message-container">
          <div className="chat-message">
            <p>
              🔒 To manage your username or password, click here:{" "}
              <a
                href="https://status.wsu.ac.za/reset/index.php"
                target="_blank"
                rel="noopener noreferrer"
              >
                reset Username/Password
              </a>
            </p>
            <p>
              Need more help? Click '<b>Live Chat</b>' on main menu.
            </p>
            <p>
              If you'd like to return to the main menu, click '<b>home</b>'
              on top.
            </p>
          </div>
        </div>
      );
    }

    const clickedItem = (
      <div className="chat-message-container chose-option">
        <div className="options-message">
          <button type="button">
            {option.emoji} {option.title}
          </button>
        </div>
      </div>
    );

    if (option.id === "5") {
      let shouldDelay = true;

      setTimeout(() => {
        if (shouldDelay) {
          window.open(
            "https://www.wsu.ac.za/index.php/en/discover-wsu/faqs",
            "_blank"
          );
          shouldDelay = false;
        }
      }, 3000);
    }
    dispatch(
      setAppendedComps([...appendedComps.slice(0, -1), clickedItem, newMessage])
    );
  };

  return (
    <>
      <div className="chat-message-container message">
        <div className="chat-message">
          Here are some <b>self-help</b> options you can choose from. Please
          select one of the following:
        </div>
      </div>
      <div className="chat-message-container chose-option">
        <div className="options-message">
          {selfHelpOptions.map((option, index) => (
            <button
              key={index}
              type="button"
              onClick={(event) => handleSelfHelpOptionClick(event, option)}
            >
              {option.emoji} {option.title}
            </button>
          ))}
        </div>
      </div>
    </>
  );
};

export default SelfHelp;
