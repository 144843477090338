const allowedIncomingMessages = {
  TYPES: {
    TEXT: "text",
    BUTTON: "button",
    DOCUMENT: "document",
    IMAGE: "image",
    INTERACTIVE_REPLY: "interactive",
  },
  MIME_TYPES: {
    PDF: "application/pdf",
    IMAGE_JPEG: "image/jpeg",
    IMAGE_PNG: "image/png",
  },
};

const channels = {
  WHATSAPP: "WHATSAPP",
  WEB_CHAT: "WEB_CHAT",
};

const chatStages = {
  INITIAL: "Initial",
  CHOOSING_CATEGORY: "CHOOSING_CATEGORY",
  CHAT_OPENED: "CHAT_OPENED",
  CLOSED: "CLOSED",
  ARCHIVED: "ARCHIVED",
};

const openChatTypes = {
  FEEDBACK: "feedback",
  SUPPORT_CONVERSATION: "supportConversation",
};

const messageSender = {
  AGENT: "AGENT",
  CLIENT: "CLIENT",
};

const createGreetingMessage = () => {
  const salutation = "Good Day";
  return `*${salutation}!* I am WSU Assistant, your friendly online support. I am able to help you with the following options😊`;
};

const createInitialResponse = async (automatedResponsesQuerySnapshot) => {
  let automatedResultsList =
    "*Make a selection* from below by choosing and replying with number.\n\n";

  let lineNumber = 1;
  automatedResponsesQuerySnapshot.forEach((doc) => {
    automatedResultsList +=
      lineNumber + ". " + doc.data().Label + " " + doc.data().Emoji + "\n";
    lineNumber++;
  });

  automatedResultsList += lineNumber + ". Other/General 🔘";

  return automatedResultsList;
};

const messages = {
  GREETING: createGreetingMessage(),
  INVALID_CHOICE: "*Invalid response*. Please reply with the correct response.",
  REDIRECT_TO_QUEUE_MESSAGE:
    "*Dear user*, we are redirecting you to a Customer Agent. Please stay online as our dedicated agent will be with you shortly. Your patience is greatly appreciated.",
  OTHER: "Other/General",
  OTHER_ID_PREFIX: "Other_",
  FIRST_MESSAGE:
    "We're redirecting you to a Customer Agent. Please stay online for assistance. Thank you for your patience.",
  FEEDBACK_THANK_YOU:
    "🙏 <b>Thank you for your feedback!</b><p>We'll use it to improve our services. If you have more to share or need assistance, just let us know.</p>If you'd like to return to the main menu, click  '<b>home</b>' on top.",
};

module.exports = {
  allowedIncomingMessages: allowedIncomingMessages,
  messages: messages,
  chatStages: chatStages,
  createInitialResponse: createInitialResponse,
  messageSender: messageSender,
  channels: channels,
  openChatTypes,
};
