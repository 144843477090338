import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  setAppendedComps,
  setOpenChatData,
  setOpenChatId,
  setShowMainOptions,
} from "../slices/webChatSlice";

const Contacts = () => {
  const dispatch = useDispatch();
  const [showMapsOptions, setShowMapsOptions] = useState(false);

  const handleClickHyperLink = (event) => {
    event.preventDefault();
    window.open(event.target.href, "_blank", "noopener,noreferrer");
  };

  const getDirection = (e) => {
    e.stopPropagation();
    setShowMapsOptions(true);
  };

  const triggerReset = () => {
    dispatch(setOpenChatId(null));
    dispatch(setOpenChatData(null));
    dispatch(setAppendedComps([]));
    dispatch(setShowMainOptions(true));
  };

  return (
    <>
      <div className="chat-message-container message">
        <div className="chat-message">
          <h6>Contacts & Directions </h6>
          <p>
            <br></br>
            📄Attached is an up-to-date <b>document</b> with phone numbers and
            email addresses for academic and non-academic departments at our
            campuses, listed alphabetically.
          </p>
          <p>
            🔍 Use the <b>search</b> function in the document to quickly find
            the contact you need.
          </p>
          <p>
            📍 Click '<b>Get Directions</b>' for campus navigation.
          </p>
          <p>
            <a
              href="https://wsu-dev-updated-afs-1.s3.af-south-1.amazonaws.com/Contacts+Book.pdf"
              onClick={handleClickHyperLink}
            >
              Updated Contacts Document.pdf
            </a>
          </p>
          {!showMapsOptions && (
            <>
              {" "}
              <button type="button" onClick={getDirection} className="chat-btn">
                Get Directions
              </button>
              <button type="button" onClick={triggerReset} className="chat-btn">
                Main Menu
              </button>
            </>
          )}
        </div>
      </div>
      {showMapsOptions && (
        <div className="chat-message-container message">
          <div className="chat-message">
            <p>
              {showMapsOptions && (
                <>
                  <b>WSU Campuses 🌐</b>
                  <p>Click below the campus you you would like to view.</p>
                  <p>
                    📍&nbsp;
                    <a
                      className="link"
                      onClick={handleClickHyperLink}
                      href="http://maps.google.com/?q=Walter Sisulu University (Mthatha Campus) NMD, Walter Sisulu University, Nelson Mandela Dr, 5099"
                    >
                      Mthatha Campus
                    </a>
                  </p>
                  <p>
                    📍&nbsp;
                    <a
                      className="link"
                      onClick={handleClickHyperLink}
                      href="http://maps.google.com/?q=Walter Sisulu University, Butterworth Campus, N2, Butterworth, 4960"
                    >
                      Butterworth Campus
                    </a>
                  </p>
                  <p>
                    📍&nbsp;
                    <a
                      className="link"
                      onClick={handleClickHyperLink}
                      href="http://maps.google.com/?q=Walter Sisulu University. College Street Campus"
                    >
                      Buffalo City Campus
                    </a>
                  </p>
                  <p>
                    📍&nbsp;
                    <a
                      className="link"
                      onClick={handleClickHyperLink}
                      href="http://maps.google.com/?q=Walter Sisulu University, Komani Campus"
                    >
                      Komani Campus
                    </a>
                  </p>
                </>
              )}
            </p>
            {showMapsOptions && (
              <button type="button" onClick={triggerReset} className="chat-btn">
                Main Menu
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Contacts;
