import React, { useEffect, useState } from "react";
import WebChat from "./components/WebChat";
import { getChatConfigs } from "./slices/api/webChatAPI";
const whiteListedDomains = ["localhost", "www.wsu.ac.za", "wsu.ac.za"];
const App = () => {
  const [chatConfigs, setChatConfigs] = useState(null);

  useEffect(() => {
    const callApi = async () => {
      try {
        const response = await getChatConfigs();
        console.log("Loaded chat successfully.");
        setChatConfigs(response);
      } catch (error) {
        console.error("Error loading MyWSU Chat.");
      }
    };

    // if (whiteListedDomains.includes(window.location.hostname)) {
    callApi();
    // }
  }, []);

  return <>{chatConfigs && chatConfigs.isEnabled ? <WebChat /> : null}</>;
};

export default App;
