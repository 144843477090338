import { configureStore } from "@reduxjs/toolkit";
import webChatSlice from "../slices/webChatSlice";

const store = configureStore({
  reducer: {
    webChat: webChatSlice.reducer,
  },
});

export default store;
