import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SendIcon from "@mui/icons-material/Send";
import { saveCustomerFeedback, updateChat } from "../slices/api/webChatAPI";
import Constants from "../constants/Constants";
import PendingIcon from "@mui/icons-material/Pending";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import {
  setAppendedComps,
  setOpenChatData,
  setOpenChatId,
  setTypeOfChatOpen,
} from "../slices/webChatSlice";

const WriteMessage = () => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { openChatId, typeOfChatOpen, appendedComps, openChatData } =
    useSelector((state) => state.webChat);

  const handleChange = (e) => setMessage(e.target.value);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!message.trim()) return;

    setIsSubmitting(true);

    if (typeOfChatOpen === Constants.openChatTypes.FEEDBACK) {
      await saveCustomerFeedback({
        feedbackContent: message,
        sender: "webChat",
        messageType: Constants.allowedIncomingMessages.TYPES.TEXT,
        dateCreated: new Date().toString(),
        isRead: false,
      });

      const text = message;
      const thankYouForFeeBackMessage = (
        <div className="chat-message-container message">
          <div className="chat-message outgoing-message">
            <p>
              <b>Your Submitted Feedback: </b> {text}
            </p>
            <p
              dangerouslySetInnerHTML={{
                __html: Constants.messages.FEEDBACK_THANK_YOU,
              }}
            ></p>
          </div>
        </div>
      );

      const newArr = [...appendedComps];
      newArr.splice(-1, 1);
      const updatedComps = [thankYouForFeeBackMessage];

      dispatch(setAppendedComps(updatedComps));
      dispatch(setTypeOfChatOpen(null));
      dispatch(setOpenChatData(null));
      setMessage("");
    } else {
      const messageObject = {
        message: {
          messageType: Constants.allowedIncomingMessages.TYPES.TEXT,
          messageContent: message,
          sender: Constants.messageSender.CLIENT,
          dateCreated: new Date().toString(),
          isRead: false,
        },
        dateUpdated: new Date().toString(),
      };

      const chatData = {
        chatId: openChatId,
        messageObject,
      };

      try {
        await updateChat(chatData);
        setMessage("");
      } catch (error) {
        console.error("Error occurred:", error);
      }
    }

    setIsSubmitting(false);
  };

  useEffect(() => {
    if (openChatData) {
      console.log(openChatData);
    }
  }, [openChatData]);

  if (!openChatData || openChatData?.status != Constants.chatStages.CHAT_OPENED)
    return (
      <>
        <div className="d-flex align-items-center justify-content-center">
          <p className="m-0 my-company">
            Powered by{" "}
            <a
              href="https://www.myplusplus.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              mY++
            </a>
          </p>
        </div>
      </>
    );

  return (
    <div className="write-message-container">
      <div className="attachments"></div>
      <div className="type-message">
        <textarea
          name="web_message"
          id="web_message"
          placeholder={`${
            typeOfChatOpen === Constants.openChatTypes.FEEDBACK
              ? "Enter anonymous feedback here. Include your name/contact if desired..."
              : "Type message..."
          }`}
          value={message}
          onChange={handleChange}
        ></textarea>

        <div className="type-message-btns">
          <button type="button">
            <AttachFileIcon />
          </button>
          <button
            type="button"
            className="send-btn"
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            {isSubmitting ? <PendingIcon /> : <SendIcon />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default WriteMessage;
