import {
  collection,
  query,
  where,
  onSnapshot,
  addDoc,
  doc,
  deleteDoc,
  arrayUnion,
  getFirestore,
  updateDoc,
  getDoc,
  setDoc,
  getDocs,
} from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { messages } from "../../constants";
import { setOpenChatData } from "../webChatSlice";

export const getAutomatedResponses = async () => {
  try {
    const automatedResponsesRef = collection(db, "ai_responses");
    const automatedResponsesQuerySnapshot = await getDocs(
      automatedResponsesRef
    );

    const automatedResponses = automatedResponsesQuerySnapshot.docs.map(
      (doc) => {
        const automatedResponseData = doc.data();
        return { id: doc.id, ...automatedResponseData };
      }
    );

    if (automatedResponses.length > 0) {
      //add other only if there are automated responses from db
      automatedResponses.push({
        Emoji: messages.OtherEmoji,
        Label: messages.OtherLabel,
        id: messages.OtherId,
      });
    }

    return automatedResponses;
  } catch (error) {
    console.log("Error getting data:", error);
    return [];
  }
};

export const getAllCategories = async () => {
  try {
    const categoriesRef = collection(db, "categories");
    const q = query(categoriesRef);
    const categoriesQuerySnapshot = await getDocs(q);

    const categories = categoriesQuerySnapshot.docs.map((doc) => {
      const categoriesData = doc.data();
      return { id: doc.id, ...categoriesData };
    });

    return categories;
  } catch (error) {
    console.log("Error getting data:", error);
    return [];
  }
};

export const getAnnouncements = async () => {
  try {
    const announcementsRef = collection(db, "announcements");
    const announcementsQuerySnapshot = await getDocs(announcementsRef);

    const announcements = announcementsQuerySnapshot.docs.map((doc) => {
      let announcementsData = doc.data();
      announcementsData.dateCreated = announcementsData.dateCreated
        .toDate()
        .toDateString();
      return { id: doc.id, ...announcementsData };
    });

    return announcements;
  } catch (error) {
    console.log("Error getting data:", error);
    return [];
  }
};

export const saveNewMessage = async (chatData) => {
  try {
    const chatRef = collection(db, "chats");
    const docRef = await addDoc(chatRef, chatData);
    const chatId = docRef.id;

    return { success: true, chatId }; // Include the document ID in the returned object
  } catch (error) {
    return { success: false, error };
  }
};

export const updateChat = async (chatData) => {
  try {
    const chatRef = doc(db, "chats", chatData.chatId);
    const payload = { dateUpdated: new Date() };

    if (chatData.messageObject && chatData.messageObject.message)
      payload.messages = arrayUnion(chatData.messageObject.message);

    if (chatData.status) payload.status = chatData.status;

    await updateDoc(chatRef, payload);

    return { success: true };
  } catch (error) {
    return { success: false, error };
  }
};

export const subscribeToChat = async (dispatch, chatId) => {
  const chatRef = doc(db, "chats", chatId); // Reference to the specific document

  const unsubscribe = onSnapshot(chatRef, (doc) => {
    if (doc.exists()) {
      const chatData = doc.data();
      chatData.dateCreated = chatData.dateCreated.toString();
      chatData.dateUpdated = chatData.dateUpdated.toString();

      const chat = { id: doc.id, ...chatData };
      dispatch(setOpenChatData(chat)); // Pass an array with a single chat to the dispatch function
    } else {
      dispatch(setOpenChatData(null)); // Document doesn't exist, clear the chats
    }
  });

  return unsubscribe;
};

export const getChatConfigs = async () => {
  const chatConfigsRef = collection(db, "configs");

  try {
    const docRef = doc(chatConfigsRef, "chatConfigs");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return { exists: true, id: doc.id, ...docSnap.data() };
    } else {
      return { exists: false };
    }
  } catch (error) {
    throw new Error({ success: false, error });
  }
  // const chatConfigsRef = collection(db, "configs");
  // try {
  //   const docRef = doc(chatConfigsRef, "chatConfigs");
  //   const docSnap = await getDoc(docRef);
  //   if (docSnap.exists) {
  //     return docSnap.data();
  //   } else {
  //     throw new Error("Department not found");
  //   }
  // } catch (error) {
  //   console.error("Error getting document: ", error);
  //   throw error;
  // }
};

export const saveCustomerFeedback = async (feedbackData) => {
  try {
    const feedbackRef = collection(db, "feedbacks");
    const docRef = await addDoc(feedbackRef, feedbackData);
    const feedbackRefId = docRef.id;

    return { success: true, feedbackRefId };
  } catch (error) {
    return { success: false, error };
  }
};
