import axios from "axios";

class SelfHelpService {
  constructor() {}

  async checkAdmissionStatus(idno, year) {
    try {
      const response = await axios.post(
        "https://kvufp2nwwcvlrz5egyqa6e25c40uxqjx.lambda-url.af-south-1.on.aws/",
        {
          year: year,
          idno: idno,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error(error);
      throw new Error(
        "An error occurred while trying to process your request."
      );
    }
  }
}

export default SelfHelpService;
