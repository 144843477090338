import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfigDev = {
  apiKey: "AIzaSyCjU3Txy0QyXt3Sx8PAD9wtCbDyJLOWqcY",
  authDomain: "my-service-desk-a8855.firebaseapp.com",
  projectId: "my-service-desk-a8855",
  storageBucket: "my-service-desk-a8855.appspot.com",
  messagingSenderId: "629480047972",
  appId: "1:629480047972:web:d44a70d87336b99393e671",
  measurementId: "G-3HZVVTL6C1",
};

const firebaseConfigProd = {
  apiKey: "AIzaSyA4-qspLbKKF-aUHVOzV0hZCCFK24WpGxo",
  authDomain: "mywsu-myplusplus.firebaseapp.com",
  projectId: "mywsu-myplusplus",
  storageBucket: "mywsu-myplusplus.appspot.com",
  messagingSenderId: "937470149115",
  appId: "1:937470149115:web:0fe852b9ae7fedcbea0b5e",
  measurementId: "G-G2BQFYXRDR",
};

const environment = process.env.ENVIRONMENT;
export const app = initializeApp(
  environment == "dev" ? firebaseConfigDev : firebaseConfigProd
);

export const db = getFirestore(app);
