export const messages = {
  Main: "Main",
  MainUpdated: "main",
  OtherLabel: "Other/General",
  OtherEmoji: "🔘",
  OtherId: "Other",
  Welcome: "Hello, I am Mi, your mY++ virtual assistant🤖",
  UnderConstructionFeatureNote:
    "We still have this feature under construction.🚧",
  Option1:
    "How may i assist you today. \nHere are the categories I can help you with at the moment.",
  Option2:
    "Here are more options based on the category you selected. Please select another category so that I may assist you with your query?",
  Option3:
    "I can't help you with that option, please fill the following inputs before i connect you with an online assistant.",
  Option4: "Connecting you to an online agent.",
  Option5: "Please wait for a minute....",
};
