import { createSlice } from "@reduxjs/toolkit";
import {
  getAutomatedResponses as getAutomatedResponsesApi,
  getAllCategories as getAllCategoriesApi,
  subscribeToChat as subscribeToChatApi,
  getAnnouncements as getAnnouncementsApi,
} from "./api/webChatAPI";

const initialState = {
  automatedResponses: [],
  displayAllAutomatedReponses: true,
  selectedActiveAutomatedResponse: null,
  categories: [],
  selectedCategory: null,
  openChatId: null,
  openChatData: null,
  appendedComps: [],
  showMainOptions: true,
  announcements: [],
  typeOfChatOpen: null,
};

const webChatSlice = createSlice({
  name: "webChat",
  initialState,
  reducers: {
    setDisplayAllMainOptions: (state, action) => {
      state.displayAllAutomatedReponses = !state.displayAllAutomatedReponses;
    },
    setSelectedMainResponse: (state, action) => {
      state.selectedActiveAutomatedResponse = action.payload;
    },
    setAutomatedResponses: (state, action) => {
      state.automatedResponses = action.payload;
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
    setOpenChatId: (state, action) => {
      state.openChatId = action.payload;
    },
    setOpenChatData: (state, action) => {
      state.openChatData = action.payload;
    },
    setAppendedComps: (state, action) => {
      state.appendedComps = action.payload;
    },
    setShowMainOptions: (state, action) => {
      state.showMainOptions = action.payload;
    },
    setAnnouncements: (state, action) => {
      state.announcements = action.payload;
    },
    setTypeOfChatOpen: (state, action) => {
      state.typeOfChatOpen = action.payload;
    },
  },
});

export const {
  setAutomatedResponses,
  setDisplayAllMainOptions,
  setSelectedMainResponse,
  setCategories,
  setOpenChatId,
  setOpenChatData,
  setAppendedComps,
  setShowMainOptions,
  setAnnouncements,
  setTypeOfChatOpen,
} = webChatSlice.actions;

export const setAutomatedResponsesAsync = async (dispatch) => {
  try {
    const automatedResponses = await getAutomatedResponsesApi();
    dispatch(setAutomatedResponses(automatedResponses));
  } catch (error) {
    console.log("Error adding chat:", error);
  }
};

export const getAllCategoriesAsync = async (dispatch) => {
  try {
    const categories = await getAllCategoriesApi();
    dispatch(setCategories(categories));
  } catch (error) {
    console.log("Error adding chat:", error);
  }
};

export const getAnnouncementsAsync = async (dispatch) => {
  try {
    const announcements = await getAnnouncementsApi(dispatch);
    dispatch(setAnnouncements(announcements));
  } catch (error) {
    console.log("Error getting announcements:", error);
  }
};

export const subscribeToChatAsync = async (dispatch, chatId) => {
  try {
    const chat = await subscribeToChatApi(dispatch, chatId);
    dispatch(setOpenChatData(chat));
  } catch (error) {
    console.log("Error adding chat:", error);
  }
};

export default webChatSlice;
