import React, { useState } from "react";
import ForumIcon from '@mui/icons-material/Forum';
import CloseIcon from '@mui/icons-material/Close';
import Header from "./Header";
import Greeting from "./Greeting";
import MainOptions from "./MainOptions";
import WriteMessage from "./WriteMessage";
import OpenChat from "./OpenChat";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const WebChat = () => {
  const [isChatOpen, setChatOpen] = useState(false);
  const { appendedComps } = useSelector((state) => state.webChat);

  const messageAlertStatus = () => {
    if(localStorage.getItem('alert-message') === null) {
      return true
    } else {
      return localStorage.getItem('alert-message')
    }
  };

  const [messageAlert, setMessageAlert] = useState(messageAlertStatus);

  const toggleChat = () => {
    setChatOpen(!isChatOpen);
    setMessageAlert(false);
  };

  

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const hideMessageAlert = () => {
    setMessageAlert(false);
    localStorage.setItem('alert-message', false);
  }

  return (
    <div id="mypp_web_chat">
      {isChatOpen && (
        <div className={`web-chat-container slide-up`}>
          <Header toggleChat={toggleChat} />
          <div className="chat-messages-container">
            <Greeting />
            <MainOptions />
            <div>{appendedComps}</div>
            <OpenChat />
          </div>
          <WriteMessage />
        </div>
      )}

      { !isChatOpen && messageAlert && 
        <div className="bot-message-alert shadow">
          <div className="message">
            <p>Hi, I am your WSU <b>Smart Assistant</b>. I can connect you with an agent or answer questions you have on WSU.</p>
            <button type="button" className="close-btn" onClick={hideMessageAlert}><CloseIcon fontSize="xsmall" /></button>
          </div>
        </div>
      }

      {!isChatOpen && 
        <button className="open-chat-btn" onClick={toggleChat}>
          <ForumIcon />
          {messageAlert && <span className="number">1</span>}
        </button>
      }
    </div>
  );
};

export default WebChat;
